import { useState } from "react";

import article_01 from "../imgs/article_01.webp"

export function useArticleData() {
    const [article, setArticle] = useState([
        {
            title: "Interviewing users: my first steps as a Product Designer",
            summary: "Six months ago I finished university, and one month later I started working at a tech company as a product designer. I’ve been learning so many things in a really short time. I was never taught user experience at university. In addition to creating apps, we were taught about UI, button basics, navigation menus and we created some useful interfaces, but what about UX? I didn’t know anything else other than the definition. But let’s see what I’ve been learning since August.",
            img_01: article_01,
            link: "https://medium.com/design-bootcamp/interviewing-users-my-first-steps-as-a-product-designer-7a98cf6f92d5",
            date: "Oct. 26th, 2022",
            time: "4min read"
        }
    ]);

    return { article, setArticle };
}
