import React, { useState } from 'react';
import '../common/header.css';
import { Link } from 'react-router-dom';
import { useIcons } from '../Icons';

function Header() {
    const { ArrowUp } = useIcons();

    const [menu, setMenu] = useState(false);
    const toggleMenu = () => {
        setMenu(!menu);
    };
    
    const handleLinkClick = () => {
        setMenu(false);
    };

    const menuIcon = (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 18H4M20 12H4M20 6H4" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    );

    const closeIcon = (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 18L18 6M6 6l12 12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );

    return (
        <header>
            <div className='top-hdr'>
                <h3><Link to="/#" onClick={handleLinkClick}>Carles Busquets</Link></h3>
                <button className='nav-btn' onClick={toggleMenu}>
                    {menu ? closeIcon : menuIcon}
                </button>
            </div>
            <nav className={`cabecera-nav ${menu ? 'isActive' : ''}`}>
                <ul>
                    <li><Link to="/Projects" onClick={handleLinkClick}>Projects</Link></li>
                    <li><Link to="/Articles" onClick={handleLinkClick}>Articles</Link></li>
                    <li><Link to="/About" onClick={handleLinkClick}>About</Link></li>
                    <li><Link to="mailto:carlesbusquets96@gmail.com" id='link-button'>Contact <ArrowUp/></Link></li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;
