import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './common/scrolltop.jsx';
import './App.css';
import './index.css';
import './common/header.css';

import Header from './common/header.jsx';
import Home from './pages/home.jsx';
import Footer from './common/footer.jsx';

import Projects from './pages/Projects.jsx';
import Vslr from './pages/projects/Vslr.jsx';
import Ek from './pages/projects/Ek.jsx';
import Dropdown from './pages/projects/Dropdown.jsx';
import MyIcons from './pages/projects/MyIcons.jsx';
import FlightPass from './pages/projects/FlightPass.jsx';
import About from './pages/About.jsx';
import Buttons from './pages/projects/Buttons.jsx';
import Reby from './pages/projects/Reby.jsx';
import Articles from './pages/Articles.jsx';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/About" element={<About />} />
          <Route path="/Articles" element={<Articles />} />

          <Route path="/vslr" element={<Vslr />} />
          <Route path="/Ek" element={<Ek />} />
          <Route path="/Dropdown" element={<Dropdown />} />
          <Route path="/MyIcons" element={<MyIcons />} />
          <Route path="/FlightPass" element={<FlightPass />} />
          <Route path="/Buttons" element={<Buttons />} />
          <Route path="/Reby" element={<Reby />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
