import { useState } from "react";

import vslrCover from "../imgs/vslr_00.webp"
import vslr_01 from "../imgs/vslr_01.webp"
import vslr_02 from "../imgs/vslr_02.webp"
import vslr_03 from "../imgs/vslr_03.webp"
import vslr_04 from "../imgs/vslr_04.webp"
import vslr_05 from "../imgs/vslr_05.webp"
import vslr_06 from "../imgs/vslr_06.webp"

import ekCover from "../imgs/ek_00.webp"
import ek_01 from "../imgs/ek_01.webp"
import ek_02 from "../imgs/ek_02.webp"
import ek_03 from "../imgs/ek_03.webp"


import dropCover from "../imgs/drop.webp"
import drop_01 from "../imgs/drop_00.webp"
import drop_02 from "../imgs/drop_01.webp"
import drop_03 from "../imgs/drop_02.webp"
import drop_04 from "../imgs/drop_03.webp"
import drop_05 from "../imgs/drop_04.webp"
import drop_06 from "../imgs/drop_05.webp"


import iconCover from "../imgs/icon-gallery.webp"
import buttonCover from "../imgs/button.webp"
import button_01 from "../imgs/button_01.webp"
import button_02 from "../imgs/button_02.webp"
import button_03 from "../imgs/button_03.webp"
import button_04 from "../imgs/button_04.webp"
import button_05 from "../imgs/button_05.webp"
import button_06 from "../imgs/button_06.webp"

import flightPassCover from "../imgs/FlightPass_00.webp";
import flightPass_01 from "../imgs/flight-pass_01.webp";
import flightPass_02 from "../imgs/flight-pass_02.webp";
import flightPass_03 from "../imgs/flight-pass_03.webp";

import rebyCover from "../imgs/reby_00.webp"
import reby_01 from "../imgs/reby_01.webp"
import reby_02 from "../imgs/reby_02.webp"
import reby_03 from "../imgs/reby_03.webp"

export function useWorkData() {
    const [project, setProject] = useState([
        {
            title: "Visual Slavery",
            topic: "Web development",
            summary: "I assist in designing and coding VisualSlavery's website, a creative production company in Barcelona. My role focuses on UI and UX design. Collaborating closely with developers, we aim to create an engaging online platform that showcases their visual and auditory artistry.",
            company: "Visual Slavery EU",
            link: "/Vslr",
            img_0: vslrCover,
            img_1: vslr_01,
            img_2: vslr_02,
            img_3: vslr_03,
            img_4: vslr_04,
            img_5: vslr_05,
            img_6: vslr_06
        },
        {
            title: "EasyKezy",
            topic: "Web development",
            summary: "An App, a Webside, and a Database for a gaming company. It was my responsibility to design and develop the home page as well as the shop page. As well as that, I also adhere to the cookie policy. Over the course of six days, we completed a multi-team project.",
            company: "EasyKezy",
            link: "/Ek",
            img_0: ekCover,
            img_1: ek_01,
            img_2: ek_02,
            img_3: ek_03,
        },
        {
            title: "Embat components",
            topic: "Design",
            summary: "An online fintech software company from Madrid commissioned this piece of artwork. With the objective of making a placeful experience for our users, I designed it by myself keeping their needs in mind.",
            company: "Embat",
            link: "/Dropdown",
            img_0: dropCover,
            img_1: drop_01,
            img_2: drop_02,
            img_3: drop_03,
            img_4: drop_04,
            img_5: drop_05,
            img_6: drop_06

        },
        {
            title: "Buttons",
            topic: "Design",
            summary: "The buttons were part of a design system I created from scratch for Embat, a Madrid-based fintech company.",
            company: "Embat",
            link: "/Buttons",
            img_0: buttonCover,
            img_1: button_01,
            img_2: button_02,
            img_3: button_03,
            img_4: button_04,
            img_5: button_05,
            img_6: button_06
        },
        {
            title: "Flight pass",
            topic: "Design",
            summary: "In my personal project, I created a flight boarding pass in the wallet app for Apple Watch, with a strong focus on hierarchy and a user-centric approach.",
            company: "Embat",
            link: "/FlightPass",
            img_0: flightPassCover,
            img_1: flightPass_01,
            img_2: flightPass_02,
            img_3: flightPass_03
        },
        {
            title: "Icon gallery",
            topic: "Design",
            summary: "I embarked on an ambitious project to design a versatile set of over 100 icons for use in UI. This collection reflects my dedication to maintaining design consistency, user-centeredness, and flexibility.",
            company: "Reby Riders",
            link: "/MyIcons",
            img_1: iconCover
        },
        {
            title: "Reby",
            topic: "Design",
            summary: "An online fintech software company commissioned this piece of artwork. With the objective of making a placeful experience for our users, I designed it by myself keeping their needs in mind.",
            company: "Reby Riders",
            link: "/Reby",
            img_0: rebyCover,
            img_1: reby_01,
            img_2: reby_02,
            img_3: reby_03

        }
    ]);

    return { project, setProject };
}



