import React from 'react';
import '../common/footer.css'

function Footer() {
    return(
        <footer>

            <div className='footer-contact'>
                <a href="/#">Carles Busquets</a>
                <span>You can check my social media.</span>

                <div className="media">
                    <a href='https://www.linkedin.com/in/carles-busquets-b781b1240/'><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 4.32353V19.6765C21 20.0275 20.8606 20.3641 20.6123 20.6123C20.3641 20.8606 20.0275 21 19.6765 21H4.32353C3.97251 21 3.63586 20.8606 3.38765 20.6123C3.13944 20.3641 3 20.0275 3 19.6765V4.32353C3 3.97251 3.13944 3.63586 3.38765 3.38765C3.63586 3.13944 3.97251 3 4.32353 3H19.6765C20.0275 3 20.3641 3.13944 20.6123 3.38765C20.8606 3.63586 21 3.97251 21 4.32353ZM8.29412 9.88235H5.64706V18.3529H8.29412V9.88235ZM8.53235 6.97059C8.53375 6.77036 8.49569 6.57182 8.42035 6.3863C8.34502 6.20078 8.23387 6.03191 8.09328 5.88935C7.95268 5.74678 7.78537 5.6333 7.60091 5.5554C7.41646 5.47749 7.21846 5.43668 7.01824 5.43529H6.97059C6.5634 5.43529 6.17289 5.59705 5.88497 5.88497C5.59705 6.17289 5.43529 6.5634 5.43529 6.97059C5.43529 7.37777 5.59705 7.76828 5.88497 8.05621C6.17289 8.34413 6.5634 8.50588 6.97059 8.50588C7.17083 8.51081 7.37008 8.47623 7.55696 8.40413C7.74383 8.33202 7.91467 8.2238 8.0597 8.08565C8.20474 7.94749 8.32113 7.78212 8.40223 7.59897C8.48333 7.41582 8.52755 7.21848 8.53235 7.01824V6.97059ZM18.3529 13.2071C18.3529 10.6606 16.7329 9.67059 15.1235 9.67059C14.5966 9.6442 14.0719 9.75644 13.6019 9.9961C13.1318 10.2358 12.7328 10.5945 12.4447 11.0365H12.3706V9.88235H9.88235V18.3529H12.5294V13.8476C12.4911 13.3862 12.6365 12.9283 12.9339 12.5735C13.2313 12.2186 13.6567 11.9954 14.1176 11.9524H14.2182C15.06 11.9524 15.6847 12.4818 15.6847 13.8159V18.3529H18.3318L18.3529 13.2071Z"/></svg></a>
                    <a href="https://dribbble.com/carles-product-design"><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.4794 2 2 6.4794 2 12C2 17.5206 6.4794 22 12 22C17.5098 22 22 17.5206 22 12C22 6.4794 17.5098 2 12 2ZM18.6052 6.60954C19.7983 8.0629 20.5141 9.91758 20.5358 11.9241C20.2538 11.8698 17.4338 11.295 14.5922 11.6529C14.5271 11.5119 14.4729 11.3601 14.4078 11.2082C14.2343 10.7961 14.039 10.3731 13.8438 9.9718C16.9892 8.69198 18.4208 6.84816 18.6052 6.60954ZM12 3.47505C14.1692 3.47505 16.154 4.2885 17.6616 5.62256C17.5098 5.83948 16.2191 7.564 13.1822 8.70282C11.7831 6.13232 10.2321 4.0282 9.9935 3.70282C10.6334 3.55098 11.3059 3.47505 12 3.47505ZM8.3666 4.27766C8.59436 4.58134 10.1128 6.69632 11.5336 9.21258C7.5423 10.2755 4.01736 10.2538 3.63774 10.2538C4.19088 7.60738 5.98048 5.40564 8.3666 4.27766ZM3.45336 12.0108C3.45336 11.9241 3.45336 11.8373 3.45336 11.7505C3.82213 11.7614 7.9653 11.8156 12.2278 10.5358C12.4772 11.013 12.705 11.5011 12.9219 11.9892C12.8134 12.0217 12.6941 12.0542 12.5857 12.0868C8.18222 13.5076 5.83948 17.3905 5.64426 17.7158C4.2885 16.2082 3.45336 14.2017 3.45336 12.0108ZM12 20.5466C10.026 20.5466 8.2039 19.8742 6.76138 18.7462C6.91324 18.4317 8.6486 15.0911 13.4642 13.41C13.4859 13.3991 13.4967 13.3991 13.5184 13.3883C14.7223 16.5011 15.2104 19.115 15.3406 19.8633C14.3102 20.308 13.1822 20.5466 12 20.5466ZM16.7614 19.0824C16.6746 18.5618 16.2191 16.0672 15.102 12.9978C17.7809 12.5748 20.1236 13.269 20.4165 13.3666C20.0477 15.7419 18.6811 17.7918 16.7614 19.0824Z"/></svg></a>
                    <a href="https://medium.com/@carles.busquets"><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.9995 11.8277C23.9995 15.007 23.4667 17.586 22.8092 17.586C22.1517 17.586 21.6191 15.0077 21.6191 11.8277C21.6191 8.6477 22.152 6.06946 22.8092 6.06946C23.4665 6.06946 23.9995 8.64747 23.9995 11.8277Z"/><path d="M20.9632 11.8277C20.9632 15.377 19.4479 18.2554 17.5788 18.2554C15.7096 18.2554 14.1943 15.377 14.1943 11.8277C14.1943 8.27834 15.7094 5.3999 17.5785 5.3999C19.4477 5.3999 20.9629 8.27742 20.9629 11.8277"/><path d="M13.5374 11.8277C13.5374 15.5984 10.5069 18.6552 6.76884 18.6552C3.03073 18.6552 0 15.5977 0 11.8277C0 8.05767 3.0305 5 6.76884 5C10.5072 5 13.5374 8.05698 13.5374 11.8277Z"/></svg></a>
                    <a href="https://github.com/CarlesBusquetsDev"><svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1_1549)"><path d="M10.538 15.7585C14.9591 14.2785 17.4406 9.05042 15.1036 4.52831C12.2916 -0.912652 4.59399 -1.26671 1.28109 3.89253C-1.62286 8.41492 0.678589 14.2746 5.48543 15.7578C5.88477 15.8311 6.0307 15.5841 6.0307 15.3734C6.0307 15.1834 6.02358 14.5534 6.01931 13.8871C3.79838 14.3697 3.32928 12.9453 3.32928 12.9453C2.96553 12.0228 2.44233 11.7772 2.44233 11.7772C1.71626 11.2818 2.49714 11.2918 2.49714 11.2918C3.29867 11.3473 3.7215 12.1146 3.7215 12.1146C4.43405 13.3354 5.59149 12.9824 6.04565 12.7774C6.11825 12.2627 6.32469 11.9096 6.55247 11.711C4.78 11.5096 2.91499 10.8241 2.91499 7.76389C2.91499 6.89118 3.22677 6.18006 3.73645 5.62055C3.6553 5.41768 3.37982 4.60547 3.81546 3.5064C3.81546 3.5064 4.4853 3.29214 6.01148 4.32501C7.31557 3.97397 8.64713 3.96205 10.0092 4.32643C11.5325 3.29214 12.2037 3.50782 12.2037 3.50782C12.6401 4.60761 12.3653 5.4191 12.2835 5.62126C12.7953 6.18006 13.1042 6.89189 13.1042 7.7646C13.1042 10.8326 11.2364 11.5082 9.45748 11.706C9.74506 11.9538 9.99918 12.4392 9.99918 13.1838C9.99918 14.2516 9.98851 15.1122 9.98851 15.3756C9.98851 15.5884 10.1337 15.8368 10.538 15.7585Z" fill="#E4E5E6"/></g><defs><clipPath id="clip0_1_1549"><rect width="16" height="16" fill="white"/></clipPath></defs></svg></a>
                </div>
                    
            </div>

            <div className='mail'>
                <p>Ux/ui, QA and Front-end dev.</p>
                <span>You can say hi:</span>
                <p>carlesbusquets96@gmail.com</p>
            </div>
            <nav>
                <ul className='link-footer'>
                    <li><a href="/Projects">Projects</a></li>
                    <li><a href="/Articles">Articles</a></li>
                    <li><a href="/About">About</a></li>
                </ul>
            </nav>
        </footer>
    )
}

export default Footer;