import React from "react";
import { useWorkData } from "../../data/works";
import "./template.css"
import { useIcons } from "../../Icons";
import { Link } from 'react-router-dom';

function FlightPass() {
    const {project} = useWorkData();
    const { ArrowSquare } = useIcons();
    const { ArrowTop } = useIcons();
    const { ArrowLeft} = useIcons();
    const { ArrowRight } = useIcons();

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <main>
                
                {project.slice(4,5).map(project => (
                    <section>
                        <div className="cover-project">
                            <img src={project.img_0} alt={project.title} />
                        </div>
                        <div className="project-title"> 
                            <h2>{project.title}</h2>
                            <h3>{project.summary}</h3>
                        </div>

                        <div className="project-exp">
                            <h3>Hierarchy and UX Study:</h3>
                            <p>I conducted a comprehensive UX study to determine what information travelers need at airports. This research revealed that passengers primarily require flight details, gate assignments, boarding times, and baggage claim information. I structured the app's layout accordingly for easy access to these crucial details.</p>
                        </div>
                        <div className="project-exp">
                            <h3>User-Centric Design:</h3>
                            <p>The app anticipates travelers' needs, offering real-time flight updates, gate change notifications, and a clear boarding pass barcode for hassle-free scanning at security and boarding gates.</p>
                        </div>
                        
                        <section className="gallery">
                            <article className="gallery-info">

                                <div className="gallery-photo">
                                    <img src={project.img_1} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>Flight ticket.</i></p>
                                </div>
                                
                            </article>

                            <article className="gallery-info">
                                
                                <div className="gallery-photo">
                                    <img src={project.img_2} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>Component.</i></p>
                                </div>
                                
                            </article>

                            <article className="gallery-info">
                                
                                <div className="gallery-photo">
                                    <img src={project.img_3} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>Card.</i></p>
                                </div>
                                
                            </article>
                            
                        </section>

                        <div className="project-exp">
                            <h3>Visual Design and Integration:</h3>
                            <p>I adopted a minimalist and intuitive user interface, optimized for Apple Watch's limited screen space. The app seamlessly integrates with the Apple ecosystem, leveraging features like notifications, haptic feedback, and voice commands for a smooth user experience.</p>
                            <p>In summary, my project created a user-friendly boarding pass app that simplifies the airport experience by providing essential information at a glance, making air travel more efficient and stress-free.</p>
                        </div>

                        <div className="project-exp">
                            <p>I added a credit card visualizer to your Apple Watch scrolling the flight boarding pass for user convenience and security. It allowed multiple card storage, secure payments, and enhanced the travel experience through streamlined access to boarding passes and payment options. Personalization options were also available for virtual credit cards.</p>
                        </div>

                        <div className="project-nav">
                            <Link id="left" to="/Buttons"><ArrowLeft/>Back</Link>
                            <Link to="#" onClick={scrollToTop}><ArrowTop />Top<ArrowTop /></Link>
                            <Link id="right" to="/MyIcons">Next<ArrowRight/></Link>
                        </div>  
                        
                    </section>
                    
                ))}
        </main>
    );
}

export default FlightPass;
