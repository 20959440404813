import React from 'react';
import { useWorkData } from "../data/works";
import { Link } from 'react-router-dom';
import './projects/template.css';
import { useIcons } from '../Icons';

function Projects() {
    const {project} = useWorkData();
    const { ArrowUp } = useIcons();
  return (
    <main>
        <section>
            <div className="project-title">
                <h2>My projects</h2>
                <p className="intro-section">This are some of the projects I've been working on during this three years career.</p>
            </div>

            <div className="article-list">

                    {project.map((project, index) => (
                        <article>
                            <div className="image-preview"><Link to={project.link}><img src={project.img_1} alt={project.title} /></Link></div>
                            <h3>{project.title}</h3>
                            <p>{project.summary.length > 118 ? project.summary.slice(0, 118) + "..." : project.summary}</p>
                            <Link to={project.link}>view full project <ArrowUp/></Link>
                        </article>
                    ))}
                    
            </div>

        </section>
      
      
    </main>
  );
};

export default Projects;
