import React from "react";
import "../pages/Articles.css";
import { useArticleData } from "../data/recomendations.jsx";
import { Link } from "react-router-dom";
import { useIcons } from "../Icons.jsx";
import { useBooksData } from "../data/libros.jsx";

function Articles() {
    const { article } = useArticleData();
    const { books } = useBooksData();
    const { ArrowUp, EyeArticle } = useIcons();

    return (
        <main>
            <section className="articles">
                <h2>Articles</h2>
                <p className="intro-section">Some times I like to writhe articles. Here you will find my experience on IT.</p>
                {article.map((article, index) => (
                    <article className="article-box" key={index}>
                        <div className="article-img">
                            <img src={article.img_01} alt={article.title} />
                        </div>
                        <div className="article-text">
                            <h3>
                                <EyeArticle /> 
                                {article.title}
                            </h3>
                            
                            <p>{article.summary.length > 292 ? article.summary.slice(0, 292) + "..." : article.summary}</p>

                            <div className="info-date">
                                <p>{article.date}</p>
                                <p>{article.time}</p>
                            </div>
                            
                            <Link to={article.link}>
                                read full article <ArrowUp />
                            </Link>
                        </div>
                    </article>
                ))}
            </section>
            <section className="books">
                <h2>My books recomendations</h2>
                <p className="intro-section">One of my passions are books, I love to spend an afternoon reading in a terrace with a big coffee.</p>
                <div className="book-box">
                    {books.map((book, index) => (
                        <article className="card" key={index}>
                            <div className="book-image">
                                <img src={book.img} alt={book.title} /> {/* Asegúrate de que la propiedad sea 'img' */}
                            </div>
                            <div className="book-text">
                                <h3>{book.title}</h3>
                                <p>{book.summary.length > 68 ? book.summary.slice(0, 68) + "..." : book.summary}</p>
                                <Link to={book.link}>
                                    read full book <ArrowUp />
                                </Link>
                            </div>
                        </article>
                    ))}
                </div>
                
            </section>
        </main>
    );
}

export default Articles;