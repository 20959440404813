// src/data/libros.jsx
import { useState } from "react";

export function useBooksData() {
    const [books, setBooks] = useState([
        {
            title: "Babel",
            author: "R. F. Kuang",
            img: "https://imagessl6.casadellibro.com/a/l/s7/86/9788419266286.webp",
            summary: "1828. Robin Swift, orphaned by cholera in Canton, is brought to London by the mysterious Professor Lovell. There, he trains for years in Latin, Ancient Greek, and Chinese, all in preparation for the day he’ll enroll in Oxford University’s prestigious Royal Institute of Translation—also known as Babel.",
            link: "https://www.casadellibro.com/libro-babel/9788419266286/13334467"
        },{
            title: "Poppy war",
            author: "R. F. Kuang",
            img: "https://imagessl3.casadellibro.com/a/l/s7/03/9788419266903.webp",
            summary: "When Rin aced the Keju – the test to find the most talented students in the Empire – it was a shock to everyone: to the test officials, who couldn’t believe a war orphan from Rooster Province could pass without cheating; to Rin’s guardians, who had hoped to get rich by marrying her off; and to Rin herself, who realized she was finally free from a life of servitude. That she got into Sinegard – the most elite military school in Nikan – was even more surprising.",
            link: "https://www.casadellibro.com/libro-la-guerra-de-la-amapola/9788419266903/14153345"
        },{
            title: "Dune",
            author: "F. Hervert",
            img: "https://imagessl8.casadellibro.com/a/l/s7/28/9788417347628.webp",
            summary: "Melange, or 'spice', is the most valuable - and rarest - element in the universe. And it can only be found on a single planet: the inhospitable desert world Arrakis.Whoever controls Arrakis controls the spice. And whoever controls the spice controls the universe.When stewardship of Arrakis is transferred to his house, Paul Atreides must travel to the planet's dangerous surface to ensure the future of his family and his people. But as malevolent forces explode into conflict around him, Paul is thrust into a great destiny beyond his understanding.",
            link: "https://www.casadellibro.com/libro-dune/9788417347628/10014535"
        },{
            title: "Fundation",
            author: "I. Asimov",
            img: "https://imagessl2.casadellibro.com/a/l/s7/42/9788418037542.webp",
            summary: "For twelve thousand years the Galactic Empire has ruled supreme. Now it is dying. But only Hari Seldon, creator of the revolutionary science of psychohistory, can see into the future—to a dark age of ignorance, barbarism, and warfare that will last thirty thousand years. To preserve knowledge and save humankind, Seldon gathers the best minds in the Empire—both scientists and scholars—and brings them to a bleak planet at the edge of the galaxy to serve as a beacon of hope for future generations. He calls his sanctuary the Foundation.",
            link: "https://www.casadellibro.com/libro-trilogia-fundacion-edicion-ilustrada/9788418037542/12744068"
        }
    ]);

    return { books, setBooks };
}