import React from "react";
import '../pages/home.css'
import { useWorkData } from "../data/works";
import { useIcons } from "../Icons";
import { Link } from "react-router-dom";

function Home() {
    const {project} = useWorkData();
    const { ArrowUp } = useIcons();
    const { ReactIcon } = useIcons();
    const { JavaScript } = useIcons();
    const { Python } = useIcons();
    const { Mongo } = useIcons();
    const { SQLite } = useIcons();
    return(
        <main>
            <section className="intro">
                <div className="content-left">
                    <h1>I’m a QA tester with a front end knowledge.</h1>
                    <h3>Over the course of my career, I have worked primarily as a Product Designer in the Fintech, Mobility, and SaaS sectors. Being a quality assurance tester as well as a front-end developer and product designer makes me an invaluable asset to any project.</h3>
                    <div className="coding-lang">

                        <JavaScript/>
                        <ReactIcon/>
                        <Python/>
                        <Mongo/>
                        <SQLite/>

                    </div>
                </div>
                <div className="content-right">
                    <div className="outstanding">
                        {project.slice(0,1).map(project => (
                            <div className="out-info">
                                <Link to={project.link}><h3>VSLR Website <ArrowUp/> </h3></Link>
                                <p>{project.summary.length > 192 ? project.summary.slice(0, 192) + '...' : project.summary}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className="project-summary">
                <h2>Some of my projects</h2>
                <p>This are the projects I’ve been working in recently...</p>

                <div className="article-list">

                    {project.slice(1, 4).map((project, index) => (
                        <article>
                            <div className="image-preview"><Link to={project.link}><img src={project.img_1} alt={project.title} /></Link></div>
                            <h3>{project.title}</h3>
                            <p>{project.summary.length > 118 ? project.summary.slice(0, 118) + "..." : project.summary}</p>
                            <Link to={project.link}>view full project <ArrowUp/></Link>
                        </article>
                    ))}
                    
                </div>

            </section>
        </main>

    );
}

export default Home;