import React from "react";
import { useWorkData } from "../../data/works";
import "./template.css"
import { useIcons } from "../../Icons";
import { Link } from 'react-router-dom';

function Dropdown() {
    const {project} = useWorkData();
    const { ArrowSquare } = useIcons();
    const { ArrowTop } = useIcons();
    const { ArrowLeft} = useIcons();
    const { ArrowRight } = useIcons();

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <main>
                
                {project.slice(2,3).map(project => (
                    <section>
                        <div className="cover-project">
                            <img src={project.img_0} alt={project.title} />
                        </div>
                        <div className="project-title"> 
                            <h2>{project.title}</h2>
                            <h3>{project.summary}</h3>
                            <p className="text-intro">The purpose of this fictional project was to create an online store selling game keys. With the help of this fake client, you will be able to purchase game keys for your game. It is also necessary for the company to have two apps in addition to its website. In my role as a member of the web team, I was responsible for the design and front-end development of the project.</p>
                        </div>

                        <div className="project-paragraf">
                            <h3>Problem</h3>
                            <p>As I reviewed Embat's old design system, I realized there were components that did not help the user achieve the objectives for which they were designed and did not speed up the development team's work, so they needed to be rethought. Identifying where that component was used was the first step. After completing the proces in the software several times, I appeared to have gained a better understanding of the shortcomings and needs of that component. </p>
                            <p className="extra-paragraf">I first noticed that the component had extra functionalities that were not fulfilling the main purpose of the component, so removing them and focusing on the main function would be the best solution.</p>
                        </div>
                        
                        
                        
                        
                        <section className="gallery">
                            <article className="gallery-info">

                                <div className="gallery-photo">
                                    <img src={project.img_1} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>Dropdown light-mode.</i></p>
                                </div>
                                
                            </article>

                            <article className="gallery-info">
                                
                                <div className="gallery-photo">
                                    <img src={project.img_2} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>Dropdown dark-mode.</i></p>
                                </div>
                                
                            </article>

                            <article className="gallery-info">
                                
                                <div className="gallery-photo">
                                    <img src={project.img_3} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>Dark-mode.</i></p>
                                </div>
                                
                            </article>

                            <article className="gallery-info">
                                
                                <div className="gallery-photo">
                                    <img src={project.img_4} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>Light-mode.</i></p>
                                </div>
                                
                            </article>

                            <article className="gallery-info">
                                
                                <div className="gallery-photo">
                                    <img src={project.img_5} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>Component base.</i></p>
                                </div>
                                
                            </article>

                            <article className="gallery-info">
                                
                                <div className="gallery-photo">
                                    <img src={project.img_6} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>Component ready to use.</i></p>
                                </div>
                                
                            </article>
                            
                        </section>

                        <div className="project-paragraf">
                            <h3>Solution</h3>
                            <p>In some components that used currency exchange, I found there was a lack of information. I proposed that when selecting a currency, the user can view the equivalence between currencies without leaving our software.</p>
                            <p className="extra-paragraf">In the case of other components such as the dropdown, the different options were not very clear and in the same component there were up to three different types of selectors. What I did with these components was to add a uniform spacing between sections, to provide gerarchy with the margins and to unify the selectors.</p>
                            <p className="extra-paragraf">Once the changes were made I tested both with development and with the user. And with the approval of my manager I incorporated these new components into the design system.</p>
                        </div>

                        <div className="project-nav">
                            <Link id="left" to="/Ek"><ArrowLeft/>Back</Link>
                            <Link to="#" onClick={scrollToTop}><ArrowTop />Top<ArrowTop /></Link>
                            <Link id="right" to="/Buttons">Next<ArrowRight/></Link>
                        </div>  
                        
                    </section>
                    
                ))}
        </main>
    );
}

export default Dropdown;
