import React from "react";
import './About.css';  // Asume que tienes estilos para About en About.css
import aboutImage from '../imgs/about.webp';
import { useIcons } from "../Icons";


function About() {
    const { Bug } = useIcons();
    const { Stars } = useIcons();
    const { Labtop } = useIcons();
    const { ArrowUp } = useIcons();
    return(
        <main className="about-page">
            <section className="about-me">
                <div className="about-left">
                    <img src={aboutImage} alt="Carles" />
                </div>
                <div className="about-right">
                    <h3>Hi, I’m Carles!</h3>
                    <p className="intro-description">My skills include product design, QA, and front-end development.</p>
                    
                    <section className="icon-desc-list">
                        <div className="icon-desc">
                            <Bug/>
                            <p>QA Tester</p>
                        </div>
                        <div className="icon-desc">
                            <Stars/>
                            <p>Designer</p>
                        </div>
                        <div className="icon-desc">
                            <Labtop/>
                            <p>Front-end</p>
                        </div>
                    </section>
                    <p className="self-description">The combination of my skills in front-end development with JavaScript and React.js, test automation with Selenium and Python, and product design using Figma makes me a unique combination of technical skills and quality assurance experience. <span> As a result of my Asperger's as well as my meticulous approach and problem-solving abilities, I am ideally suited to contribute to the development and continuous improvement of high-quality software products.</span></p>
                    <a id="pdf-button" href={require("../data/Curriculum-Vitae_CarlesBusquets.pdf")}>View my CV <ArrowUp/></a>
                </div>
            </section>
            
        </main>
    )
}
export default About;