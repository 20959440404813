import React from "react";
import { useWorkData } from "../../data/works";
import "./template.css"
import { useIcons } from "../../Icons";
import { Link } from "react-router-dom";

function Vslr() {
    const {project} = useWorkData();
    const { ArrowSquare } = useIcons();
    const { ArrowTop } = useIcons();
    const { ArrowLeft} = useIcons();
    const { ArrowRight } = useIcons();

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <main>
                
                {project.slice(0,1).map(project => (
                    <section>
                        <div className="cover-project">
                            <img src={project.img_0} alt={project.title} />
                        </div>
                        <div className="project-title"> 
                            <h2>{project.title}</h2>
                            <h3>{project.summary}</h3>
                            <p className="text-intro">This involves understanding their goals, creating wireframes and mockups, and ensuring a cohesive and responsive design. I also optimize multimedia content for fast loading, implement audio features, and conduct user testing for a seamless user experience. Our goal is to enhance VisualSlavery's online presence and connect with a global audience who appreciates their unique art.</p>
                        </div>
                        <section className="gallery">
                            <article className="gallery-info">

                                <div className="gallery-photo">
                                    <img src={project.img_1} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>Visual Slavery EU gallery-page.</i></p>
                                </div>
                                
                            </article>

                            <article className="gallery-info">
                                
                                <div className="gallery-photo">
                                    <img src={project.img_2} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>Visual productions page.</i></p>
                                </div>
                                
                            </article>

                            <article className="gallery-info">
                                
                                <div className="gallery-photo">
                                    <img src={project.img_3} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>Services.</i></p>
                                </div>
                                
                            </article>

                            <article className="gallery-info">
                                
                                <div className="gallery-photo">
                                    <img src={project.img_4} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>Special effects section.</i></p>
                                </div>
                                
                            </article>

                            <article className="gallery-info">
                                
                                <div className="gallery-photo">
                                    <img src={project.img_5} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>3D and graphic design.</i></p>
                                </div>
                                
                            </article>

                            <article className="gallery-info">
                                
                                <div className="gallery-photo">
                                    <img src={project.img_6} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>Video and photography.</i></p>
                                </div>
                                
                            </article>
                            
                        </section>

                        <p>I played a significant role in developing VisualSlavery's website, leveraging my expertise in HTML, CSS, and Kirby notions. Two key areas where my contributions were instrumental were the gallery, the header and the footer sections. In the header, I applied my HTML and CSS skills to create a responsive design that seamlessly integrated VisualSlavery's branding elements. I paid attention to detail, incorporating engaging animations and transitions to enhance user interaction and overall aesthetics. Similarly, in the footer, I utilized my HTML and CSS expertise to make it both functional and informative. I included essential elements like contact information, social media links, and a copyright notice. Thanks to my proficiency in Kirby notions, I ensured dynamic content management, keeping the copyright year up to date and seamlessly integrating a contact form.In summary, my coding skills in HTML, CSS, and Kirby notions were pivotal in delivering a visually appealing, responsive, and high-performing website. These contributions significantly elevated VisualSlavery's online presence in Barcelona and beyond.</p>
            
                        <div className="project-nav">
                            <Link id="left" to="/Vslr"><ArrowLeft/>Back</Link>
                            <Link to="#" onClick={scrollToTop}><ArrowTop />Top<ArrowTop /></Link>
                            <Link id="right" to="/Ek">Next<ArrowRight/></Link>
                        </div>

                    </section>
                    
                ))}
        </main>
    );
}

export default Vslr;
