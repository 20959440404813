import React from "react";
import { useWorkData } from "../../data/works";
import "./template.css"
import { useIcons } from "../../Icons";
import { Link } from "react-router-dom";

function Reby() {
    const {project} = useWorkData();
    const { ArrowSquare } = useIcons();
    const { ArrowTop } = useIcons();
    const { ArrowLeft} = useIcons();
    const { ArrowRight } = useIcons();

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    return (
        <main>
                
                {project.slice(6,7).map(project => (
                    <section>
                        <div className="cover-project">
                            <img src={project.img_0} alt={project.title} />
                        </div>
                        <div className="project-title"> 
                            <h2>{project.title}</h2>
                            <h3>{project.summary}</h3>
                            <p className="text-intro">My goal as a product designer is to make the online experience pleasant for users. As soon as I detect that something is amiss, I try to fix it. I work for a mobility app that rents out motorbikes, bikes, and scooters for a certain period of time. One day, our metrics revealed that the Scan QR screen after the ride was not working. The CX department had to assist many confused users. As a result, I was motivated to solve this problem through the User Interface under Pablo's guidance.</p>
                        </div>

                        <div className="project-paragraf">
                            <h3>Problem</h3>
                            <p>We so that users were scanning a wrong QR from our vehicles, the right code was on the front, between the handlebar. Keeping this in mind, our first step is to add a new screen so that the user can be guided into the correct flow. You won't find any help screen and the "?" icon on the bar will not take you anywhere. And this made that people couldn’t finish the ride.</p>
                        </div>
                        
                        <section className="gallery">
                            <article className="gallery-info">

                                <div className="gallery-photo">
                                    <img src={project.img_1} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>My screen design.</i></p>
                                </div>
                                
                            </article>

                            <article className="gallery-info">
                                
                                <div className="gallery-photo">
                                    <img src={project.img_2} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>Final proposse.</i></p>
                                </div>
                                
                            </article>

                            <article className="gallery-info">
                                
                                <div className="gallery-photo">
                                    <img src={project.img_3} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>Previous screen.</i></p>
                                </div>
                                
                            </article>
                            
                        </section>
                        
                        <div className="project-paragraf">
                            <h3>Solution</h3>
                            <p>So the first thing we decide was to create a help screen that works as onboarding the first time you travel. The next times you can access to this screen with the help icon at the top of the scan QR cam. Then we analyzed our cam screen and we tried to optimize a new version to make the process easier for the users. We also create a screen for the wrong QR scan and we write a short explanation to get the user into a correct flow.</p>
                            <p className="extra-paragraf">One week later we start seeing the results, users calling for the custom experience department decrease. We check the metrics again and we so that the number of users that finish the ride increased and also they spend less time on this process.</p>
                        </div>
                        <p></p>
            
                        <div className="project-nav">
                            <Link id="left" to="/MyIcons"><ArrowLeft/>Back</Link>
                            <Link to="#" onClick={scrollToTop}><ArrowTop />Top<ArrowTop /></Link>
                            <Link id="right" to="/Vslr">Next<ArrowRight/></Link>
                        </div>

                    </section>
                    
                ))}
        </main>
    );
}

export default Reby;
