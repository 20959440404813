import React from "react";
import { useWorkData } from "../../data/works";
import "./template.css"
import { useIcons } from "../../Icons";
import { Link } from "react-router-dom";

function Buttons() {
    const {project} = useWorkData();
    const { ArrowSquare } = useIcons();
    const { ArrowTop } = useIcons();
    const { ArrowLeft} = useIcons();
    const { ArrowRight } = useIcons();

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    return (
        <main>
                
                {project.slice(3,4).map(project => (
                    <section>
                        <div className="cover-project">
                            <img src={project.img_0} alt={project.title} />
                        </div>
                        <div className="project-title"> 
                            <h2>{project.title}</h2>
                            <h3>{project.summary}</h3>
                            <p className="text-intro">The colors could be changed based on the state of the button, from active to pressed. As well as deciding the perfect size and how it reacts to icons, I also buy the sides.</p>
                        </div>
                        <section className="gallery">
                            <article className="gallery-info">

                                <div className="gallery-photo">
                                    <img src={project.img_1} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>Button component.</i></p>
                                </div>
                                
                            </article>

                            <article className="gallery-info">
                                
                                <div className="gallery-photo">
                                    <img src={project.img_2} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>States.</i></p>
                                </div>
                                
                            </article>

                            <article className="gallery-info">
                                
                                <div className="gallery-photo">
                                    <img src={project.img_3} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>Sizes.</i></p>
                                </div>
                                
                            </article>

                            <article className="gallery-info">
                                
                                <div className="gallery-photo">
                                    <img src={project.img_4} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>How it works.</i></p>
                                </div>
                                
                            </article>

                            <article className="gallery-info">
                                
                                <div className="gallery-photo">
                                    <img src={project.img_5} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>Light mode.</i></p>
                                </div>
                                
                            </article>

                            <article className="gallery-info">
                                
                                <div className="gallery-photo">
                                    <img src={project.img_6} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>Dark mode.</i></p>
                                </div>
                                
                            </article>
                            
                        </section>

                        <p>It was my responsibility to play a crucial role in the creation of the design system. To create a great contrast between the brand identity and my color choices, I followed the color scheme of the brand. I am able to create useful design systems based on the client's strategy because of my ability to control Figma.</p>
            
                        <div className="project-nav">
                            <Link id="left" to="/Dropdown"><ArrowLeft/>Back</Link>
                            <Link to="#" onClick={scrollToTop}><ArrowTop />Top<ArrowTop /></Link>
                            <Link id="right" to="/FlightPass">Next<ArrowRight/></Link>
                        </div>

                    </section>
                    
                ))}
        </main>
    );
}

export default Buttons;
