import React from "react";
import { useWorkData } from "../../data/works";
import "./template.css"
import { useIcons } from "../../Icons";
import { Link } from 'react-router-dom';

function Ek() {
    const {project} = useWorkData();
    const { ArrowSquare } = useIcons();
    const { ArrowTop } = useIcons();
    const { ArrowLeft} = useIcons();
    const { ArrowRight } = useIcons();

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <main>
                
                {project.slice(1,2).map(project => (
                    <section>
                        <div className="cover-project">
                            <img src={project.img_0} alt={project.title} />
                        </div>
                        <div className="project-title"> 
                            <h2>{project.title}</h2>
                            <h3>{project.summary}</h3>
                            <p className="text-intro">The purpose of this fictional project was to create an online store selling game keys. With the help of this fake client, you will be able to purchase game keys for your game. It is also necessary for the company to have two apps in addition to its website. In my role as a member of the web team, I was responsible for the design and front-end development of the project.</p>
                        </div>
                        <section className="gallery">
                            <article className="gallery-info">

                                <div className="gallery-photo">
                                    <img src={project.img_1} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>The home-page.</i></p>
                                </div>
                                
                            </article>

                            <article className="gallery-info">
                                
                                <div className="gallery-photo">
                                    <img src={project.img_2} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>Outstanding.</i></p>
                                </div>
                                
                            </article>

                            <article className="gallery-info">
                                
                                <div className="gallery-photo">
                                    <img src={project.img_3} alt="" />
                                </div>
                                <div className="gallery-text">
                                    <ArrowSquare/>
                                    <p><i>Top sells.</i></p>
                                </div>
                                
                            </article>
                            
                        </section>

                        <p>In my role as a designer, I was responsible for the design of the home page and the interface of the shop, as well as coding bought pages to take the user from the beginning of the buying process to the end of the buying process after they had made a purchase, and then to the checkout page. I was also involved in the process of creating responsive sizes with the members of my team. There was a need to make it possible for users to purchase products via their smartphone in order to make the process easier. During my time with my team, we have created an interface that makes it easy and quick for our customers to buy our products.</p>

                        <div className="project-nav">
                            <Link id="left" to="/Vslr"><ArrowLeft/>Back</Link>
                            <Link to="#" onClick={scrollToTop}><ArrowTop />Top<ArrowTop /></Link>
                            <Link id="right" to="/Dropdown">Next<ArrowRight/></Link>
                        </div>  
                        
                    </section>
                    
                ))}
        </main>
    );
}

export default Ek;
